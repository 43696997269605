<template>
  <div class="card">
    <div class="bg-blue-light px-1 rounded pb-2">
      <TitleButton
          class="mt-1"
          btnTitle="List"
          :showBtn="true"
          :showAddNew="false"
          title="Add Stock Transfer"
          @onClickAddNewButton="navigateToListPage"
      />

      <div class="row mt-2 gy-1">
        <div class="row mt-1">
          <div class="col-sm-4">
            <label class="align-middle">Transfer From</label>
            <v-select
                placeholder="Select Warehouse"
                v-model="formData.from_id"
                :options="warehouses"
                label="text"
                :reduce="text => text.id"
            />
          </div>
          <div class="col-sm-4"></div>
          <div class="col-sm-4">
            <label class="align-middle">Transfer To</label>
            <v-select
                placeholder="Select Warehouse"
                v-model="formData.to_id"
                :options="warehouses"
                label="text"
                :reduce="text => text.id"
            />
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-sm-4">
            <label class="align-middle">Transfer Date</label>
            <input type="date" class="form-control" v-model="formData.date">
          </div>
          <div class="col-sm-4"></div>
          <div class="col-sm-4">
            <label class="align-middle">Transfer No</label>
            <input type="text" class="form-control" v-model="formData.transfer_no">
          </div>
        </div>
      </div>
    </div>

    <div class="px-2 mt-2">
      <AddItemDetails
          class="mb-2"
          :products="products"
          v-for="(item, index) in formData.item_details"
          :key="index"
          :index="index"
          :item="item"
          :fromId="fromId"
          @onClose="onClose"
      />
    </div>
    <div class="row me-1 mt-3 px-2">
      <div class="col-12 col-sm-4 col-lg-8 mb-1 mb-sm-0">
        <AddButton title="Add line" @onClickAdd="onClickAdd"/>
      </div>
    </div>

    <div class="p-1">
      <div class="row mt-3 px-2">
        <div class="col-12">
          <div class="mb-2">
            <label for="note" class="form-label fw-bold">Note</label>
            <textarea v-model="formData.note" placeholder="Note" class="form-control" rows="2" id="note"></textarea>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-2 d-flex justify-content-start justify-content-sm-center px-2 gap-1 flex-wrap pb-2">
      <hr class="hr-full-width mb-1">
      <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(true)"
              class="btn btn-primary">
        Save
      </button>
      <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(false)"
              class="btn btn-primary">Save & New
      </button>
      <button @click="navigateToListPage" class="btn btn-outline-secondary">Cancel</button>
    </div>

    <GlobalLoader/>
  </div>
</template>
<script setup>
import {computed, inject, onMounted, ref, watch} from 'vue'
import {useRoute, useRouter} from "vue-router";
import TitleButton from '@/components/atom/TitleButton'
import AddItemDetails from "@/components/molecule/procurement/inventory/stock/transfer/AddItemDetails";
import AddButton from "@/components/atom/AddButton";
import {useStore} from "vuex";
import handleBusinessesLocations from "@/services/modules/businessesLocations";
import handleStock from "@/services/modules/stock";

const $router = useRouter()
const $route = useRoute()
const $store = useStore()
const showError = inject('showError');
const showSuccess = inject('showSuccess')
const {fetchBusinessLocationsList, fetchProductsFromBusinessLocation} = handleBusinessesLocations();
const {fetchStockTransferNumber, storeStockTransfer} = handleStock();

let saveButtonLoader = ref(false)
let saveNewButtonLoader = ref(false)
let warehouses = ref([])
let products = ref([])
let prefix = ref('')
let serial = ref('')
let formData = ref({
  from_id: null,
  to_id: null,
  date: null,
  transfer_no: null,
  note: null,
  item_details: [
    {
      product_id: null,
      transfer_qty: null,
      note: null
    }
  ],
})

//computed
const fromId = computed(() => formData.value.from_id)

const navigateToListPage = () => {
  $router.push({name: `stock-transfer-list`, params: $route.params, query: $route.query});
}

const resetForm = () => {
    formData.value = {
        from_id: null,
        to_id: null,
        date: null,
        transfer_no: null,
        item_details: [
            {
                product_id: null,
                transfer_qty: null,
                note: null
            }
        ],
    }
}

const handleSubmit = (redirect=false) => {
    $store.state.loading = true;
    let query = `?company_id=${$route.params.companyId}`;
    if(redirect) {
        saveButtonLoader.value = true
    } else {
        saveNewButtonLoader.value = true
    }
    if(!formData.value.date) formData.value.date = new Date().toISOString().split('T')[0]
    let copyFormData = JSON.parse(JSON.stringify(formData.value))
    copyFormData.item_details = JSON.stringify(formData.value.item_details)
    storeStockTransfer(query, copyFormData)
        .then(res => {
            saveButtonLoader.value = false
            saveNewButtonLoader.value = false

            if(res.status) {
                showSuccess(res.message)
                if(redirect) navigateToListPage()
                resetForm()
            }
            if(!res.status) {
                showError(res.message)
            }
        })
        .catch(err=> {
            saveButtonLoader.value = false;
            saveNewButtonLoader.value = false;
            $store.state.loading = false;
            showError(err)
        })
}

const onClose = (index) => {
  formData.value.item_details.splice(index, 1)
}

const onClickAdd = () => {
  formData.value.item_details.push({
    product_id: null,
    transfer_qty: null,
    note: null
  })
}

const getProductList = warehouseId => {
    let query = `?company_id=${$route.params.companyId}&warehouse_id=${warehouseId}`;
    products.value = [];
    $store.state.loading = true;
    fetchProductsFromBusinessLocation(query)
        .then( res => {
            if (res.status === false) {
                showError(res.message)
            }
            products.value = res.data;
        })
        .catch( err => {
            showError('Something went wrong!')
        })
        .finally( () => $store.state.loading = false)
}

//watchers
watch(fromId, (newValue, oldValue) => {
    if (newValue !== null) {
        getProductList(newValue);
    }
})

onMounted(  () => {
    let query = `?company_id=${$route.params.companyId}`;
    formData.value.date = new Date().toISOString().split('T')[0]
    Promise.all([
        fetchBusinessLocationsList(query).then(res => {
            if (res.data) {
                warehouses.value = res.data;
            }
        }),
        fetchStockTransferNumber(query).then( res => {
            if (res.data) {
                formData.value.transfer_no = res.data;
            }
        })
    ])
})
</script>